import React from 'react';
import { Components, Formio, Templates } from '@tsed/react-formio';
import tailwind from '@tsed/tailwind-formio';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Booking from './components/FormComponents/Booking/Booking';
import StepSummary from './components/FormComponents/StepSummary/StepSummary';

Formio.use(tailwind);
Components.addComponent('booking', Booking);
Components.addComponent('stepSummary', StepSummary);
Templates.framework = 'tailwind';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
